const STATUS = {
  ERROR: 'ERROR',
  PENDING: 'PENDING',
  OK: 'OK',
};

const LANGS = [
  'de',
  'en',
];

export {
  STATUS,
  LANGS,
};
